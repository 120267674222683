import React from 'react'
import Navbar from '../components/navbar';
import HeroImage from '../components/HeroImage';

import Footer from '../components/Footer';
const home = () => {
  return (
    <div>
      <Navbar/>
      <HeroImage/>
      <Footer/>
    </div>
  )
}

export default home;

import React from 'react';
import Navbar from '../components/navbar';


import Footer from '../components/Footer';
import HeroImage2 from '../components/HeroImage2';
import PricingCard from '../components/PricingCard';
import WorkCard from '../components/WorkCard';

const project = () => {
  return (
    <div>
      <Navbar/>
      <HeroImage2 heading="PROJECTS." text="This are my recent works"/>
      <WorkCard/>
      <PricingCard/>
      <Footer/>
    </div>
  )
}

export default project;

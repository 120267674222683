import React from 'react';
import Navbar from '../components/navbar';

import HeroImage2 from '../components/HeroImage2';
import IntoImg from "../assets/contact.jpeg";
import { Link } from "react-router-dom";
import Footer from '../components/Footer';

const contact = () => {
  return (
    <div>
      <Navbar/>
      {/* <HeroImage2 heading="CONTACTS." text="Dial to Us"/> */}
      <div className='hero'>
    <div className='mask'>
        <img className='into-img' src={IntoImg} alt='intro image'/>
    </div>
      <div className='content'>
       <p>24 hours Service</p>
       <h1>Contact Us</h1>
         <div>
            <a href='tel:+94713089291' className='btn btn-light'>
            Call
            </a>

            
         </div>   
      </div>
    </div>
      <Footer/>
    </div>
  )
}

export default contact

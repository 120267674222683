import React from 'react';
import "./HeroImage.css";
import IntoImg from "../assets/bg.jpeg";
import { Link } from "react-router-dom";

const HeroImage = () => {
  return (
    <div className='hero'>
    <div className='mask'>
        <img className='into-img' src={IntoImg} alt='intro image'/>
    </div>
      <div className='content'>
       {/* <p>This is our motto</p> */}
       <h1>TurbineCharge</h1>
         {/* <div>
            <Link to="/project" className='btn'>
                Project
            </Link>

            <Link to="/contact" className='btn btn-light'>
                Contact
            </Link>
         </div>    */}
      </div>
    </div>
  )
}

export default HeroImage;

import "./Footer.css";

import React from 'react';
import { FaFacebook, FaHome,FaLinkedin,FaMailBulk,FaPhone, FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <div className="location">
            <FaHome size={20} style={{ color: "white", marginRight: "2rem" }} />
            <div>
            <p>123 Housing Society, </p>
            <p>Sri Lanka </p>
          </div>
          </div>
            <div className="phone">
             <h4>
             <FaPhone size={20} style={{ color: "white", marginRight: "2rem" }} />
             +94-70-46-84-339
             </h4>
            
            </div>

            <div className="email">
             <h4>
             <FaMailBulk size={20} style={{ color: "white", marginRight: "2rem" }} />
             powerpod@gmail.com
             </h4>
            
            </div>
        </div>
        <div className="right">
          {/* Other right content */}
          <h4>About the company</h4>
          <p>
            
Powerpod offers portable turbine chargers, providing eco-friendly power for adventurers. Harness wind energy and stay connected wherever you go with Powerpod
          </p>
          <div className="social">
            <FaFacebook size={30} style={{ color: "white", marginRight: "1rem" }} />
            <FaTwitter size={30} style={{ color: "white", marginRight: "1rem" }} />
            <FaLinkedin size={30} style={{ color: "white", marginRight: "1rem" }} />

          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

import React from 'react';
import Navbar from '../components/navbar';
import HeroImage2 from '../components/HeroImage2';
import IntoImg from "../assets/aboutus.jpeg";
import Footer from '../components/Footer';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <div>
      <Navbar/>
      <div className='hero'>
        <div className='mask'>
          <img className='into-img' src={IntoImg} alt='intro image'/>
        </div>
        <div className='content'>
          <h1>About Us</h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            Power Pod creates innovative, eco-friendly energy solutions for outdoor adventurers. We combine sustainability with cutting-edge technology to keep you connected on your journey, whereever it takes you.
          </motion.p>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default About;
